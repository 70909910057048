//https://codemental.medium.com/gatsby-p5-js-1dd085f42100   -> l'exemple de l'implémentation avec class pour Gatsby
export default function SketchSnowflakes(p5) {
  let stars = []

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight)
  }

  p5.draw = () => {
    p5.background(40)

    stars.push(new star())
    for (let fragment of stars) {
      fragment.show(time)
    }
  }

  function time() {
    p5.frameCount(60)
  }

  function star() {
    this.x = p5.random(0, p5.width)
    this.y = p5.random(0, p5.height)
    this.z = p5.random(0, p5.width)
    this.size = 10
    this.show = function () {
      p5.fill(240)
      p5.noStroke()
      for (let i = 0; i < 100; i++) {
        for (let j = 0; j < 100; j++) {
          p5.rect(this.x * i, this.y * j, this.size, this.size)
        }
      }
    }
  }
}
